<template>
  <div class="body container">
    <Breadcrumb>
      <template slot="lastPage">
        {{ pageTitle }}
      </template>
    </Breadcrumb>
    <h1 class="text-center mt-5 mb-3">
      <span>{{ pageTitle }}</span>
      <template v-if="isRoleAdmin()">
        <span key="edit" class="action-icon" @click="editReceivable">
          <i class="fas fa-edit" />
        </span>
        <DeleteIconButton :onDelete="deleteReceivable()" />
      </template>
    </h1>
    <el-row class="rowGap">
      <el-col>
        <h2>
          <div v-if="editTitle" style="display: flex">
            {{ $t("bill.form.billName") }}
            <el-input style="width: 500px" v-model="workBillTitle" />
            <el-button type="primary" @click="updateReceivable">
              {{ $t("button.save") }}
            </el-button>
            <el-button type="danger" @click="closeEditReceivableTitle">
              {{ $t("button.cancel") }}
            </el-button>
          </div>
          <span v-else>
            <el-button
              v-if="isRoleAdmin()"
              type="primary"
              @click="openEditReceivableTitle"
            >
              {{ $t("button.editBillName") }}
            </el-button>
          </span>
        </h2>
      </el-col>
    </el-row>
    <el-row v-if="$store.getters['bill/billReceivableEnrollmentId']" class="rowGap">
      <el-col :span="3">{{ $t("bill.form.unpaidEnrollment") }}</el-col>
      <el-col :span="21">
        <router-link
          :to="{
            name: 'EnrollmentDetail',
            params: { id: $store.getters['bill/billReceivableEnrollmentId'] }
          }"
        >
          {{ $store.getters["bill/billReceivableEnrollmentTitle"][$i18n.locale] }}
        </router-link>
      </el-col>
    </el-row>
    <el-row class="rowGap">
      <el-col>
        <OrderItems
          :viewMode="true"
          :displaySelectedCheckbox="false"
          :isReceivableMode="true"
          :action="false"
          :isPaid="isPaid"
          :billIsCreated="true"
          :orderItems="$store.getters['bill/orderItems']"
          :showTotalPrice="$store.getters['bill/billTotalPrice']"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import roleMixin from "@/mixins/role";
import moneyMixin from "@/mixins/money";
import Breadcrumb from "@/components/Breadcrumb";
import OrderItems from "@/components/bill/OrderItems.vue";
import DeleteIconButton from "@/components/button/DeleteIconButton";
import { EnumBillStatuses } from "@/enums";

export default {
  metaInfo() {
    return {
      title: `${this.pageTitle} - Ivy-Way Academy`
    };
  },
  components: {
    Breadcrumb,
    OrderItems,
    DeleteIconButton
  },
  data() {
    return {
      editTitle: false,
      workBillTitle: ""
    };
  },
  computed: {
    EnumBillStatuses() {
      return EnumBillStatuses;
    },
    pageTitle() {
      return `${this.$store.getters["bill/billTitle"]} ${this.$t("pageTitle.billView")}`;
    },
    isPaid() {
      return this.$store.getters["bill/billStatus"] === this.EnumBillStatuses.finished;
    }
  },
  mixins: [roleMixin, moneyMixin],
  async created() {
    await this.initialExchangeRate();
    await this.getBillAndConvertPrice();
  },
  methods: {
    openEditReceivableTitle() {
      this.workBillTitle = this.$store.getters["bill/billTitle"];
      this.editTitle = true;
    },
    closeEditReceivableTitle() {
      this.workBillTitle = "";
      this.editTitle = false;
    },
    async getBillAndConvertPrice() {
      await this.$store.dispatch(
        "bill/getReceivable",
        this.$route.params.receivableId
      );
      this.$store.dispatch(
        "bill/setOrderItems",
        this.addPriceByBillCurrency(
          this.$store.getters["bill/orderItems"],
          "price",
          "priceCurrency"
        )
      );
    },
    editReceivable() {
      this.$router.push({
        name: "editReceivable",
        params: {
          receivableId: this.$route.params.receivableId
        }
      });
    },
    deleteReceivable() {
      return async () => {
        try {
          await this.$store.dispatch(
            "bill/deleteReceivable",
            this.$route.params.receivableId
          );
          this.$router.push({ name: "Receivable" });
        } catch (e) {
          switch (e.data.message) {
            case "Item has been connected.":
              this.$message.error("這筆未來款項已經有部分應收項目被建立成帳單，如果要刪除請先刪除對應帳單。");
              break;
            default:
              this.$message.error(this.$t("message.something_went_wrong"));
          }
          throw { ...e };
        }
      };
    },
    addPriceByBillCurrency(list, priceColumn, priceCurrencyColumn) {
      return list.map(item => ({
        ...item,
        [`${priceColumn}ByCurrency`]: this.convertPriceByCurrency(
          item[priceColumn],
          item[priceCurrencyColumn],
          this.$store.getters["bill/billCurrency"]
        )
      }));
    },
    async updateReceivable() {
      try {
        this.$store.commit("bill/setBillTitle", this.workBillTitle);
        await this.$store.dispatch(
          "bill/updateReceivable",
          this.$route.params.receivableId
        );
        this.editTitle = false;
        this.$message.success(this.$t("message.update_success"));
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    }
  }
};
</script>

<style scoped>
.rowGap {
  margin: 20px 0px;
}

::v-deep .is-icon {
  width: 120px;
}

.el-table__row:hover .el-steps ::v-deep .is-icon {
  background: rgb(245, 247, 250);
}
</style>
